import React, { lazy, Suspense, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Logout } from "@mui/icons-material";
import Toolbar from "@mui/material/Toolbar";
import { Route, Routes, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import StoreIcon from "@mui/icons-material/Store";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";

import {
  Avatar,
  Badge,
  Card,
  CardContent,
  Grid,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useAuth } from "../../Context/AuthenticationContex";
import Index from "../authentication/Index";
import NotFound from "../notFound";
import DashboardSuperman from "../dashboardSuperman";
import Dashboard from "../dashboard";
import Loading from "../general/Loading";

import Register from "../authentication/register";

const Account = lazy(() => import("../account"));
const Product = lazy(() => import("../product"));
const UserLists = lazy(() => import("../user/User"));
const ChangePasswordFnct = lazy(
  () => import("../authentication/changePassword")
);
const RegisterStaff = lazy(() => import("../user/RegisterStaff"));
const UpdateStaff = lazy(() => import("../user/UpdateUser"));
const Buy = lazy(() => import("../buy_sale"));
const SetPasswordFnct = lazy(() => import("../user/SetPassword"));
const BuyProduct = lazy(() => import("../buy_sale/BuyProduct"));
const Stock = lazy(() => import("../reports/stock"));
const UnitList = lazy(() => import("../unit"));
const RegisterUnit = lazy(() => import("../unit/registerUnit"));
const UpdateUnit = lazy(() => import("../unit/updateUnit"));
const UpdateProduct = lazy(() => import("../product/updateProduct"));
const BrandList = lazy(() => import("../brand"));
const RegisterBrand = lazy(() => import("../brand/registerBrand"));
const UpdateBrand = lazy(() => import("../brand/updateBrand"));
const RegisterProduct = lazy(() => import("../product/registerProduct"));
const Sale = lazy(() => import("../buy_sale/Sale"));
const Sales = lazy(() => import("../buy_sale/Sales"));
const Shops = lazy(() => import("../company"));
const Payments = lazy(() => import("../company/licence"));
const ShopProfile = lazy(() => import("../company/shopProfile"));
const AddAccountCode = lazy(() => import("../account/addAccountCode"));
const UpdateAccount = lazy(() => import("../account/updateAccount"));
const Expense = lazy(() => import("../transaction/Expense"));
const AddExpense = lazy(() => import("../transaction/addExpense"));
const Income = lazy(() => import("../transaction/Income"));
const AddIncome = lazy(() => import("../transaction/AddIncome"));
const drawerWidth = 240;
export default function Layout() {
  const authCtx = useAuth();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [calLogout, setcalLogout] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const hanndelMenuClick = (
    url: string,
    selectedMenu = 1,
    event: React.MouseEvent
  ) => {
    event.stopPropagation();
    setSelectedIndex(selectedMenu);
    navigate(url);
    if (url === "/profile" || url === "/logout") {
      setAnchorEl(null);
    }
    setMobileOpen(!mobileOpen);
  };
  const logout = () => {
    authCtx?.logout(authCtx.token);
  };
  useEffect(() => {
    if (authCtx?.isLoggedIn) {
      let timer1 = setTimeout(() => setcalLogout(true), 800 * 60 * 1000);
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [authCtx?.isLoggedIn]);

  if (calLogout) {
    if (authCtx?.token) {
      authCtx?.logout(authCtx.token);
    }
  }
  if (!authCtx?.isLoggedIn) {
    return (    
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="register" element={<Register />} />
        <Route path="*" element={<Index />} />
      </Routes>
    );
  } else {
    const drawer = (
      <>
        <Toolbar />
        <List>
          <React.Fragment key={1}>
            {authCtx?.role === "manager" ? (
              <>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 1}
                    onClick={(event: any) =>
                      hanndelMenuClick("/dashboard", 1, event)
                    }
                  >
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Dashboard"} />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 2}
                    onClick={(event) => hanndelMenuClick("/sale", 2, event)}
                  >
                    <ListItemIcon>
                      <ShoppingCartIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Uza (Sale)"} />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 4}
                    onClick={(event) => hanndelMenuClick("/buy", 4, event)}
                  >
                    <ListItemIcon>
                      <LoyaltyIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Nunua (Buy)"} />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 3}
                    onClick={(event) => hanndelMenuClick("/sales", 3, event)}
                  >
                    <ListItemIcon>
                      <StoreIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Mauzo (Sales)"} />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 5}
                    onClick={(event) => hanndelMenuClick("/store", 5, event)}
                  >
                    <ListItemIcon>
                      <StoreIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Akiba (Stock)"} />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 6}
                    onClick={(event) => hanndelMenuClick("/unit", 6, event)}
                  >
                    <ListItemIcon>
                      <StoreIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Kizio (Unit)"} />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 7}
                    onClick={(event) => hanndelMenuClick("/brand", 7, event)}
                  >
                    <ListItemIcon>
                      <StoreIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Brand"} />
                  </ListItemButton>
                </ListItem>

                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 8}
                    onClick={(event) => hanndelMenuClick("/product", 8, event)}
                  >
                    <ListItemIcon>
                      <StoreIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Bidhaa (Product)"} />
                  </ListItemButton>
                </ListItem>

                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 9}
                    onClick={(event) => hanndelMenuClick("/account", 9, event)}
                  >
                    <ListItemIcon>
                      <CreditCardIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Aina za malipo (Payment Category)"}
                    />
                  </ListItemButton>
                </ListItem>

                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 10}
                    onClick={(event) => hanndelMenuClick("/expense", 10, event)}
                  >
                    <ListItemIcon>
                      <CreditCardIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Matumizi (Expenses)"} />
                  </ListItemButton>
                </ListItem>

                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 11}
                    onClick={(event) => hanndelMenuClick("/income", 11, event)}
                  >
                    <ListItemIcon>
                      <StoreIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Mapato (Income)"} />
                  </ListItemButton>
                </ListItem>

                {/* <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 11}
                    onClick={(event) =>
                      hanndelMenuClick("/profitLose", 11, event)
                    }
                  >
                    <ListItemIcon>
                      <CreditCardIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Faida/Hasara (Profit/lose)"} />
                  </ListItemButton>
                </ListItem> */}

                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 12}
                    onClick={(event) => hanndelMenuClick("/user", 12, event)}
                  >
                    <ListItemIcon>
                      <SupervisedUserCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Wafanyakazi (Staff)"} />
                  </ListItemButton>
                </ListItem>
              </>
            ) : authCtx?.role === "staff" ? (
              <>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 2}
                    onClick={(event) => hanndelMenuClick("/sale", 2, event)}
                  >
                    <ListItemIcon>
                      <ShoppingCartIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Uza (Sale)"} />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 4}
                    onClick={(event) => hanndelMenuClick("/buy", 4, event)}
                  >
                    <ListItemIcon>
                      <LoyaltyIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Nunua (Buy)"} />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 3}
                    onClick={(event) => hanndelMenuClick("/sales", 3, event)}
                  >
                    <ListItemIcon>
                      <StoreIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Mauzo (Sales)"} />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 5}
                    onClick={(event) => hanndelMenuClick("/store", 5, event)}
                  >
                    <ListItemIcon>
                      <StoreIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Akiba (Stock)"} />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 6}
                    onClick={(event) => hanndelMenuClick("/unit", 6, event)}
                  >
                    <ListItemIcon>
                      <StoreIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Kizio (Unit)"} />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 7}
                    onClick={(event) => hanndelMenuClick("/brand", 7, event)}
                  >
                    <ListItemIcon>
                      <StoreIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Brand"} />
                  </ListItemButton>
                </ListItem>

                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 7}
                    onClick={(event) => hanndelMenuClick("/product", 7, event)}
                  >
                    <ListItemIcon>
                      <StoreIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Bidhaa (Product)"} />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 9}
                    onClick={(event) => hanndelMenuClick("/expense", 9, event)}
                  >
                    <ListItemIcon>
                      <CreditCardIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Matumizi (Expenses)"} />
                  </ListItemButton>
                </ListItem>
              </>
            ) : (
              <>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 1}
                    onClick={(event) =>
                      hanndelMenuClick("/dashboardSuperman", 3, event)
                    }
                  >
                    <ListItemIcon>
                      <SupervisedUserCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Dashboard"} />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 2}
                    onClick={(event) => hanndelMenuClick("/shops", 2, event)}
                  >
                    <ListItemIcon>
                      <AccountBalanceIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Shops"} />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 3}
                    onClick={(event) => hanndelMenuClick("/payments", 3, event)}
                  >
                    <ListItemIcon>
                      <CreditCardIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Payments"} />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 4}
                    onClick={(event) => hanndelMenuClick("/user", 4, event)}
                  >
                    <ListItemIcon>
                      <SupervisedUserCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Users"} />
                  </ListItemButton>
                </ListItem>
              </>
            )}
            <Divider />
          </React.Fragment>
        </List>
        {authCtx.role !== "superman" ? (
          <Grid item xs={12} md={12} xl={12} p={3}>
            <Card
              sx={{
                backgroundColor: "#7986cb",
                color: "white",
              }}
            >
              <CardContent>
                <p>
                  {"Leseni yako inaisha tarehe " +
                    authCtx.validityDate +
                    " Zimebaki siku " +
                    authCtx.daysRemain}
                </p>
                <p>
                  {"Your Licence is valid Until " +
                    authCtx.validityDate +
                    " " +
                    authCtx.daysRemain +
                    " Days Remain"}
                </p>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          ""
        )}
      </>
    );
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <Grid container>
              <Grid item xs={2} md={2}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerToggle}
                  sx={{ display: { sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Box sx={{ display: "flex" }}>DAFTARI</Box>
            </Grid>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <IconButton
                onClick={(event) => hanndelMenuClick("/sale", 1, event)}
                size="small"
                sx={{ ml: 2 }}
              >
                <Badge badgeContent={authCtx?.catItem} color="secondary">
                  <ShoppingCartIcon style={{ color: "white" }} />
                </Badge>
              </IconButton>
              <Tooltip title="Account">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar sx={{ width: 40, height: 40 }}>
                    {authCtx.loginUser.charAt(0)}
                  </Avatar>
                  <Typography variant="overline" color="#fff" mr={2} ml={1}>
                    {authCtx.loginUser}
                  </Typography>
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {/* <MenuItem onClick={(event) => {}}>
                <Avatar /> Profile
              </MenuItem>
              <Divider /> */}
              <MenuItem
                onClick={(event) =>
                  hanndelMenuClick("/changePassword", 1, event)
                }
              >
                <ListItemIcon>
                  <VpnKeyIcon />
                </ListItemIcon>
                Badili Nywila
              </MenuItem>
              <Divider />
              <MenuItem onClick={logout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Toka (Logout)
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open={mobileOpen}
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route path="register" element={<Register />} />
                <Route path="/" element={<Dashboard />} />
                <Route
                  path="dashboardSuperman"
                  element={<DashboardSuperman />}
                />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="account" element={<Account />} />
                <Route path="sale" element={<Sale />} />
                <Route path="sales" element={<Sales />} />
                <Route path="unit" element={<UnitList />} />
                <Route path="brand" element={<BrandList />} />
                <Route path="registerBrand" element={<RegisterBrand />} />
                <Route path="registerUnit" element={<RegisterUnit />} />
                <Route path="updateBrand/:id" element={<UpdateBrand />} />
                <Route path="updateUnit/:id" element={<UpdateUnit />} />
                <Route path="product" element={<Product />} />
                <Route path="addProduct" element={<RegisterProduct />} />
                <Route path="shops" element={<Shops />} />
                <Route path="payments" element={<Payments />} />
                <Route path="user" element={<UserLists />} />
                <Route path="changePassword" element={<ChangePasswordFnct />} />
                <Route path="registerStaff" element={<RegisterStaff />} />
                <Route path="shopProfile/:id" element={<ShopProfile />} />
                <Route path="updateStaff/:id" element={<UpdateStaff />} />
                <Route path="updateProduct/:id" element={<UpdateProduct />} />
                <Route path="setPassword/:id" element={<SetPasswordFnct />} />
                <Route path="buy" element={<Buy />} />
                <Route path="buyProduct" element={<BuyProduct />} />
                <Route path="store" element={<Stock />} />
                <Route path="addAccountCode" element={<AddAccountCode />} />
                <Route path="expense" element={<Expense />} />
                <Route path="addExpense" element={<AddExpense />} />
                <Route path="income" element={<Income />} />
                <Route path="addIncome" element={<AddIncome />} />
                <Route path="updateAccount/:id" element={<UpdateAccount />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </Box>
        </Box>
      </Box>
    );
  }
}
