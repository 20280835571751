import React from "react";
import { FadeLoader } from "react-spinners";

export default function Loading() {
  return (
    <div
      className="ring-loader"
      style={{
        minHeight: "100vh",
        display: "grid",
        placeContent: "center",
        flex: 1,
      }}
    >
      <FadeLoader radius="50%" height="15px" loading={true} />
    </div>
  );
}
