import React from "react";
import ReactDOM from "react-dom/client";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

var theme = createTheme({
  palette: {
    primary: {
      main: "#7986cb",
    },
    secondary: {
      main: "#006064",
    },
  },
});
theme = responsiveFontSizes(theme);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
