import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

import React from "react";
// define your custom font
const customFont = {
  fontFamily: "Lucida Sans",
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
};

// create a theme with your custom font
const theme = createTheme({
  typography: {
    fontFamily: customFont.fontFamily,
    fontWeightRegular: customFont.fontWeightRegular,
    fontWeightMedium: customFont.fontWeightMedium,
    fontWeightBold: customFont.fontWeightBold,
  },
});
export default function Logo() {
  return (
    <Box sx={{ textAlign: "center" }}>
      <ThemeProvider theme={theme}>
        <Typography variant="h4">DAFTARI</Typography>
      </ThemeProvider>
      <img src="logo512.png" alt="logo" width={120} height={120} />
    </Box>
  );
}
