import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MuiAlert, { AlertProps, AlertColor } from "@mui/material/Alert";
import axiosInstance from "../../api/axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import MaterialUICard from "../general/MaterialUICard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import StoreIcon from "@mui/icons-material/Store";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useAuth } from "../../Context/AuthenticationContex";
const DASHBOARD_URL = "/reports";

function Dashboard() {
  const authCtx = useAuth();
  const navigate = useNavigate();
  const [dateFrom, SetDateFrom] = useState<string>();
  const [dateTo, SetDateTo] = useState<string>();
  const [message_type, SetMessageType] = useState<AlertColor>("success");
  const [MessageOpen, setMessageOpen] = useState(false);
  const [notification_message, SetMessage] = useState("");
  const [TotalBuy, setTotalBuy] = useState(0);
  const [TotalSales, setTotalSales] = useState(0);
  const [expenses, setexpenses] = useState(0);
  const [profitLose, setprofitLose] = useState(0);
  const [income, setincome] = useState(0);
  const [stockValues, setstockValues] = useState(0);
  const [topSales, settopSales] = useState([]);

  const handleCloseMessage = () => {
    setMessageOpen(false);
  };
  useEffect(() => {
    const params = {
      dateFrom: dateFrom,
      dateTo: dateTo,
    };
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${authCtx?.token}`;
    axiosInstance
      .get(DASHBOARD_URL, { params })
      .then((response: any) => {
        setTotalBuy(response.data.data.TotalBuy);
        setTotalSales(response.data.data.TotalSales);
        setexpenses(response.data.data.expenses);
        setprofitLose(response.data.data.profitLose);
        setincome(response.data.data.income);
        setstockValues(response.data.data.stockValues);
        settopSales(response.data.data.topSales);
      })
      .catch((error) => {
        if (!error.response) {
          SetMessage("Samahani!, Huduma Haipatikani. Wasiliana na mtoa huduma");
          SetMessageType("error");
        } else {
          SetMessage(error.response.data.message);
          SetMessageType("error");
        }
      });
  }, [navigate, dateFrom, dateTo, authCtx?.token]);

  const columns: GridColDef[] = [
    {
      field: "product_name",
      headerName: "JINA LA BIDHAA (PRODUCT NAME) ",
      minWidth: 300,
    },
    {
      field: "brand",
      headerName: "BRAND ",
      minWidth: 300,
    },
    {
      field: "q",
      headerName: "IDADI ZILIZOUZWA (QUANTITY SOLD)",
      minWidth: 300,
    },
  ];
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  return (
    <Box sx={{ width: "100%" }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Snackbar
          open={MessageOpen}
          onClose={handleCloseMessage}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert severity={message_type} sx={{ width: "100%" }}>
            {notification_message}!
          </Alert>
        </Snackbar>
        <Grid
          item
          xs={12}
          container
          spacing={2}
          style={{ marginLeft: 12, alignContent: "center" }}
        >
          {authCtx?.role !== "superman" ? (
            <p style={{ fontStyle: "italic", color: "rgb(84, 93, 142)", fontSize: 16, marginLeft: 12 }}>
              {"Karibu kwenye Daftari, Welcome to Daftari, Jina la duka (Shop Name): " +
                authCtx?.company?.toUpperCase()}
            </p>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} container spacing={2} style={{ marginLeft: 12 }}>
          <Grid item xs={3} xl={2}>
            <Stack>
              <TextField
                type="date"
                label="Tarehe ya kwanza (Start Date)"
                value={dateFrom}
                onChange={(event) => SetDateFrom(event.target.value)}
                variant="outlined"
                focused
              />
            </Stack>
          </Grid>
          <Grid item xs={3} xl={2}>
            <Stack>
              <TextField
                type="date"
                label="Tarehe ya mwisho (End Date)"
                value={dateTo}
                onChange={(event) => SetDateTo(event.target.value)}
                variant="outlined"
                focused
              />
            </Stack>
          </Grid>
          <Grid item xs={3} xl={2}>
            <Stack>
              {dateFrom ? (
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  size="small"
                  onClick={(e) => {
                    SetDateFrom("");
                    SetDateTo("");
                  }}
                >
                  Ondoa Tarehe (Clear Date)
                </Button>
              ) : (
                ""
              )}
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2} style={{ marginLeft: 12 }}>
          <Grid item xs={12} md={4} xl={3}>
            <MaterialUICard
              title="Mauzo (Sales)"
              description={TotalSales?.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )}
              icon={<ShoppingCartIcon />}
            />
          </Grid>          
          <Grid item xs={12} md={4} xl={3}>
            <MaterialUICard
              title="Manunuzi (Buy)"
              description={TotalBuy?.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )}
              icon={<StoreIcon />}
            />
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <MaterialUICard
              title="Faida/Hasara (Profit/Lose)"
              description={profitLose
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              icon={<AccountBalanceIcon />}
            />
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <MaterialUICard
              title="Matumizi (Expenses)"
              description={expenses
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              icon={<CreditCardIcon />}
            />
          </Grid>

          <Grid item xs={12} md={4} xl={3}>
            <MaterialUICard
              title="Mapato (Income)"
              description={income
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              icon={<CreditCardIcon />}
            />
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <MaterialUICard
              title="Thamani ya akiba (Stock Value)"
              description={stockValues
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              icon={<AccountBalanceIcon />}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2} style={{ marginLeft: 12 }}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box
                p={2}
                sx={{
                  backgroundColor: "primary.dark",
                }}
              >
                <Typography variant="h6" component="h2" color={"white"}>
                  Bidhaa maarufu (Popular Products)
                </Typography>
              </Box>
              <Divider />
              <DataGrid
                rows={topSales}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
                checkboxSelection={false}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;
