import React from "react";
import { Box, Container, Typography } from "@mui/material";

export default function Footer() {
  return (
    <Box>
      <Container maxWidth="xl">
        <Typography variant="body2" align="center" style={{ fontStyle: 'italic', color: '#7986cb', fontSize: 16}}>
          {"Mali Bila Daftari, Huisha Bila Habari "}
        </Typography>
      </Container>
    </Box>
  );
}

