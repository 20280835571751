import React, { useEffect, useState } from "react";
// material-ui
import {
  Button,
  Grid,
  MenuItem,
  Stack,
  Snackbar,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import MuiAlert, { AlertProps, AlertColor } from "@mui/material/Alert";
import axiosInstance from "../../api/axios";
import { useNavigate } from "react-router";
import Footer from "../layout/Footer";
import Logo from "../layout/Logo";
import MainCard from "../MainCard";
interface Errors {
  first_name?: string;
  last_name?: string;
  sex?: string;
  name?: string;
  phone_number?: string;
  region_id?: string;
  address?: string;
  email?: string;
  password?: string;
  password_confirmation?: string;
}
interface OptionSex {
  id: string;
  label: string;
}
interface OptionRegion {
  id: string;
  name: string;
}
const REGION_URL = "/region-list";
const REGISTER_URL = "/register";
const Register = () => {
  const [first_name, setfirst_name] = useState<string>("");
  const [last_name, setlast_name] = useState<string>("");
  const [sex, setsex] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [phone_number, setphone_number] = useState<string>("");
  const [region_id, setregion_id] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [address, setaddress] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [password_confirmation, setpassword_confirmation] =
    useState<string>("");
  const [errors, setErrors] = useState<Errors>({});
  const [optionsSex, setOptionsSex] = useState<OptionSex[]>([]);
  const [optionsRegion, setOptionsRegion] = useState<OptionRegion[]>([]);
  const [notification_message, SetMessage] = useState("");
  const [message_type, SetMessageType] = useState<AlertColor>("success");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const fetchedOptions: OptionSex[] = [
      { id: "Male", label: "Me (Male)" },
      { id: "Female", label: "Ke (Female)" },
    ];
    setOptionsSex(fetchedOptions);
    axiosInstance
      .get(REGION_URL)
      .then((response) => {
        setOptionsRegion(response.data.data.data);
      })
      .catch((error) => {
        if (!error.response) {
          SetMessage(
            "Samahani!, Huduma Haipatikani. Wasiliana na mtoa huduma (Service not found)"
          );
          SetMessageType("error");
        } else {
          SetMessage(error.response.data.message);
          SetMessageType("error");
        }
        setOpen(true);
      });
  }, []);
  const hanndelMenuClick = (url: string) => {
    navigate(url);
  };
  const validateForm = () => {
    let valid = true;
    const newErrors: Errors = {};

    if (!first_name.trim()) {
      newErrors.first_name = "Hii inahitajika (This field is required)";
      valid = false;
    }
    if (!last_name.trim()) {
      newErrors.last_name = "Hii inahitajika (This field is required)";
      valid = false;
    }

    if (!sex.trim()) {
      newErrors.sex = "Hii inahitajika (This field is required)";
      valid = false;
    }
    if (!name.trim()) {
      newErrors.name = "Hii inahitajika (This field is required)";
      valid = false;
    }
    if (!phone_number.trim()) {
      newErrors.phone_number = "Hii inahitajika (This field is required)";
      valid = false;
    } else if (!/^(\+|\d{2})?(0\d|\d){8,14}$/.test(phone_number)) {
      newErrors.phone_number =
        "Namba ya simu sio sahihi (Invalid Phone Number)";
      valid = false;
    }
    if (!region_id.trim()) {
      newErrors.region_id = "Hii inahitajika (This field is required)";
      valid = false;
    }
    if (email.trim() && !/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email sio sahihi (Invalid email)";
      valid = false;
    }

    if (!password.trim()) {
      newErrors.password = "Nyila ni lazima (Password is required)";
      valid = false;
    } else if (password.trim().length < 4) {
      newErrors.password =
        "Nyila angalau iwe na herufi 4 (Password should be at least 4 characters)";
      valid = false;
    }

    if (!password_confirmation.trim()) {
      newErrors.password_confirmation =
        "Rudia nywila ni lazima (Confirm password is required)";
      valid = false;
    } else if (password !== password_confirmation) {
      newErrors.password_confirmation =
        "Rudia nywila haifanani na nywila (Passwords do not match)";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validateForm()) {
      axiosInstance
        .post(REGISTER_URL, {
          name: name,
          phone_number: phone_number,
          region_id: region_id,
          address: address,
          email: email,
          first_name: first_name,
          last_name: last_name,
          sex: sex,
          password: password,
          password_confirmation: password_confirmation,
        })
        .then((response) => {
          SetMessage(response.data.message);
          SetMessageType("success");
          setOpen(true);
          hanndelMenuClick("/");
        })
        .catch((error) => {
          if (!error.response) {
            SetMessage(
              "Samahani!, Huduma Haipatikani. Wasiliana na mtoa huduma (Service not found)"
            );
            SetMessageType("error");
          } else {
            SetMessage(error.response.data.message);
            SetMessageType("error");
          }
          setOpen(true);
        });
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  return (
    <Box>
      <Grid container direction="column" justifyContent="flex-end">
        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <MainCard
                sx={{
                  maxWidth: { xs: 400, sm: 500, md: 600, lg: 700, xl: 800 },
                  margin: { xs: 2.5, md: 3 },
                  "& > *": {
                    flexGrow: 1,
                    flexBasis: "60%",
                  },
                }}
                border={false}
                boxShadow
              >
                <Box sx={{ p: { xs: 2, sm: 2, md: 2, xl: 2 } }}>
                  <Logo />
                  <Snackbar
                    open={open}
                    onClose={handleClose}
                    autoHideDuration={3000}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <Alert severity={message_type} sx={{ width: "100%" }}>
                      {notification_message}!
                    </Alert>
                  </Snackbar>
                  <Typography
                    variant="h5"
                    style={{ textAlign: "center", marginBottom: 16 }}
                  >
                    SAJILI (REGISTER)
                  </Typography>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={6} xl={6}>
                        <Stack spacing={1}>
                          <TextField
                            label="First Name (Jina la kwanza)*"
                            value={first_name}
                            onChange={(event) =>
                              setfirst_name(event.target.value)
                            }
                            variant="outlined"
                            error={Boolean(errors.first_name)}
                            helperText={errors.first_name}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6}>
                        <Stack spacing={1}>
                          <TextField
                            label="Last Name (Jina la Mwisho)*"
                            value={last_name}
                            onChange={(event) =>
                              setlast_name(event.target.value)
                            }
                            variant="outlined"
                            error={Boolean(errors.last_name)}
                            helperText={errors.last_name}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6}>
                        <Stack spacing={1}>
                          <TextField
                            select
                            label="Sex (Jinsi)*"
                            value={sex}
                            onChange={(event) => setsex(event.target.value)}
                            variant="outlined"
                            error={Boolean(errors.sex)}
                            helperText={errors.sex}
                          >
                            {optionsSex.map((OptionSex) => (
                              <MenuItem key={OptionSex.id} value={OptionSex.id}>
                                {OptionSex.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6}>
                        <Stack spacing={1}>
                          <TextField
                            label="Shop name (Jina la duka)*"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            variant="outlined"
                            error={Boolean(errors.name)}
                            helperText={errors.name}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6}>
                        <Stack spacing={1}>
                          <TextField
                            label="Phone number (Namba ya simu)*"
                            value={phone_number}
                            onChange={(event) =>
                              setphone_number(event.target.value)
                            }
                            variant="outlined"
                            error={Boolean(errors.phone_number)}
                            helperText={errors.phone_number}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6}>
                        <Stack spacing={1}>
                          <TextField
                            select
                            label="Region (Mkoa)*"
                            value={region_id}
                            onChange={(event) =>
                              setregion_id(event.target.value)
                            }
                            variant="outlined"
                            error={Boolean(errors.region_id)}
                            helperText={errors.region_id}
                          >
                            {optionsRegion.map((optionRegion) => (
                              <MenuItem
                                key={optionRegion.id}
                                value={optionRegion.id}
                              >
                                {optionRegion.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6}>
                        <Stack spacing={1}>
                          <TextField
                            label="Street (Mtaa)"
                            value={address}
                            onChange={(event) => setaddress(event.target.value)}
                            variant="outlined"
                            error={Boolean(errors.address)}
                            helperText={errors.address}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6}>
                        <Stack spacing={1}>
                          <TextField
                            label="Email Address (Barua pepe)"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            variant="outlined"
                            error={Boolean(errors.email)}
                            helperText={errors.email}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6}>
                        <Stack spacing={1}>
                          <TextField
                            type="password"
                            label="Password (Neno siri ) *"
                            value={password}
                            onChange={(event) =>
                              setPassword(event.target.value)
                            }
                            variant="outlined"
                            error={Boolean(errors.password)}
                            helperText={errors.password}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6}>
                        <Stack spacing={1}>
                          <TextField
                            type="password"
                            label="Confirm Password (Thibitisha nenosiri) *"
                            value={password_confirmation}
                            onChange={(event) =>
                              setpassword_confirmation(event.target.value)
                            }
                            variant="outlined"
                            error={Boolean(errors.password_confirmation)}
                            helperText={errors.password_confirmation}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6}>
                        <Stack spacing={1}>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                          >
                            Sajili (Register)
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </MainCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Register;
