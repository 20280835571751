import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "vnd.api+json",
    Accept: "application/vnd.api+json",
  },
  // withCredentials: true,
});
export default axiosInstance;
