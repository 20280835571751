import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../../Context/AuthenticationContex";
import MainCard from "../MainCard";
import Logo from "../layout/Logo";
import Footer from "../layout/Footer";

interface Errors {
  phone_number?: string;
  password?: string;
}

export default function LoginForm() {
  const authCtx = useAuth();
  const [phone_number, setPhoneNumber] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errors, setErrors] = useState<Errors>({});
  const validateForm = () => {
    let valid = true;
    const newErrors: Errors = {};

    if (!phone_number.trim()) {
      newErrors.phone_number = "Hii inahitajika (This field is required)";
      valid = false;
    } else if (!/^(\+|\d{2})?(0\d|\d){8,14}$/.test(phone_number)) {
      newErrors.phone_number =
        "Namba ya simu sio sahihi (Invalid Phone Number)";
      valid = false;
    }
    if (!password.trim()) {
      newErrors.password = "Nyila ni lazima (Password is required)";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (validateForm()) {
      authCtx?.login({
        username: phone_number,
        password: password,
      });
    }
  };
  return (
    <Box>
      <Grid container direction="column" justifyContent="flex-end">
        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <MainCard
                sx={{
                  maxWidth: { xs: 400, sm: 500, md: 600, lg: 700, xl: 800 },
                  margin: { xs: 2.5, md: 3 },
                  "& > *": {
                    flexGrow: 1,
                    flexBasis: "60%",
                  },
                }}
                border={false}
                boxShadow
              >
                <Box sx={{ p: { xs: 2, sm: 2, md: 2, xl: 2 } }}>
                  <Logo />
                  <Typography
                    variant="h5"
                    style={{ textAlign: "center", marginBottom: 16 }}
                  >
                    INGIA (LOGIN)
                  </Typography>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          <TextField
                            label="Phone number (Namba ya simu)*"
                            value={phone_number}
                            onChange={(event) =>
                              setPhoneNumber(event.target.value)
                            }
                            variant="outlined"
                            error={Boolean(errors.phone_number)}
                            helperText={errors.phone_number}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          <TextField
                            type="password"
                            label="Password (Neno siri ) *"
                            value={password}
                            onChange={(event) =>
                              setPassword(event.target.value)
                            }
                            variant="outlined"
                            error={Boolean(errors.password)}
                            helperText={errors.password}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6}>
                        <Stack spacing={1}>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                          >
                            Ingia (Login)
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </MainCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </Box>
  );
}
