import React, {
  useState,
  createContext,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import axiosInstance from "../api/axios";
import { getDateDifferenceInDays } from "../importantFunction";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps, AlertColor } from "@mui/material/Alert";
const LOGIN_URL = "/login";
const LOGOUT_URL = "/logout";
const CART_COUNT_URL = "/cart/cartCount";
type usernamePassword = {
  username: string;
  password: string;
};

type LoginValuesTypes = {
  isLoggedIn: boolean;
  company: string;
  catItem: string;
  token: string;
  role: string;
  loginUser: string;
  daysRemain: string;
  validityDate: string;
  login: (params: usernamePassword) => {};
  logout: (token: string) => {};
};

const AuthenticationContext = createContext<LoginValuesTypes | null>(null);

export function useAuth() {
  return useContext(AuthenticationContext);
}

export function AuthProvider({ children }: { children: ReactNode }) {
  const [company, setCompany] = useState("");
  const [catItem, setCatItem] = useState("");
  const [token, setToken] = useState("");
  const [role, setRole] = useState("");
  const [loginUser, setLoginUser] = useState("");
  const [daysRemain, setDaysRemain] = useState("");
  const [validityDate, setValidityDate] = useState("");
  const [isLoggedIn, setisLoggedIn] = useState(false);

  const [notification_message, SetMessage] = useState("");
  const [message_type, SetMessageType] = useState<AlertColor>("success");
  const [open, setOpen] = useState(false);

  function login(params: usernamePassword) {
    axiosInstance
      .post(LOGIN_URL, {
        username: params.username,
        password: params.password,
      })
      .then((response) => {
        setisLoggedIn(true);
        if (response.data.data.user.company) {
          setCompany(response.data.data.user.company["name"]);
          setCatItem(response.data.data.cartCount);
        }

        setToken(response.data.data.token);
        setRole(response.data.data.user.role);
        setLoginUser(
          response.data.data.user["first_name"] +
            " " +
            response.data.data.user["last_name"]
        );
        if (response.data.data.user.role !== "superman") {
          setDaysRemain(
            getDateDifferenceInDays(
              response.data.data.user.company["licence_validity"]
            ).toString()
          );
          setValidityDate(response.data.data.user.company["licence_validity"]);
        }
      })
      .catch((error) => {
        console.log(error);
        if (!error.response) {
          SetMessage(
            "Samahani!, Huduma Haipatikani. Wasiliana na mtoa huduma (Service not found)"
          );
          SetMessageType("error");
        } else {
          SetMessage(error.response.data.message);
          SetMessageType("error");
        }
        setOpen(true);
      });
    return true;
  }
  useEffect(() => {
    const jsonString = localStorage.getItem("value");
    if (jsonString !== null) {
      const parsedObject = JSON.parse(jsonString);
      setisLoggedIn(parsedObject.isLoggedIn);
      setCompany(parsedObject.company);
      setCatItem(parsedObject.catItem);
      setToken(parsedObject.token);
      setRole(parsedObject.role);
      setLoginUser(parsedObject.loginUser);
      setDaysRemain(parsedObject.daysRemain);
      setValidityDate(parsedObject.validityDate);
    }
  }, []);
  useEffect(() => {
    if (isLoggedIn) {
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      axiosInstance
        .get(CART_COUNT_URL)
        .then((response) => {
          if (response.status === 200) {
            setCatItem(response.data.data);
          }
        })
        .catch((error) => {
          if (!error.response) {
            SetMessage(
              "Samahani!, Huduma Haipatikani. Wasiliana na mtoa huduma"
            );
            SetMessageType("error");
          } else {
            SetMessage(error.response.data.message);
            SetMessageType("error");
          }
        });
    }
  }, [isLoggedIn, token]);

  function logout() {
    axiosInstance
      .post(LOGOUT_URL)
      .then((response) => {})
      .catch((error) => {
        setOpen(true);
      });
    setisLoggedIn(false);
    setCompany("");
    setCatItem("");
    setToken("");
    setRole("");
    setLoginUser("");
    setDaysRemain("");
    setValidityDate("");
    localStorage.removeItem("value");
    return true;
  }
  var value: LoginValuesTypes = {
    isLoggedIn,
    company,
    catItem,
    token,
    role,
    loginUser,
    daysRemain,
    validityDate,
    login,
    logout,
  };
  if (isLoggedIn) {
    localStorage.setItem("value", JSON.stringify(value));
  }
  if (localStorage.getItem("value") && !isLoggedIn) {
    const jsonString = localStorage.getItem("value");
    if (jsonString !== null) {
      const parsedObject = JSON.parse(jsonString);
      value = {
        isLoggedIn: parsedObject.isLoggedIn,
        company: parsedObject.company,
        catItem: parsedObject.catItem,
        token: parsedObject.token,
        role: parsedObject.role,
        loginUser: parsedObject.loginUser,
        daysRemain: parsedObject.daysRemain,
        validityDate: parsedObject.validityDate,
        login: parsedObject.login,
        logout: parsedObject.logout,
      };
    }
  }

  const handleClose = () => {
    setOpen(false);
  };
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  return (
    <AuthenticationContext.Provider value={value}>
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert severity={message_type} sx={{ width: "100%" }}>
          {notification_message}!
        </Alert>
      </Snackbar>
      {children}
    </AuthenticationContext.Provider>
  );
}
