import { Box, Typography } from "@mui/material";

export default function NotFound() {
  return (
    <Box textAlign="center" m={10}>
      <Typography variant="h1" gutterBottom>
        404
      </Typography>
      <Typography variant="h5" gutterBottom>
        Oops! Kurasa Haipatikani (The page you are looking for could not be
        found.)
      </Typography>
    </Box>
  );
}
