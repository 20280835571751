import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";

export default function Price() {
  return (
    <>
     <Typography variant="h6" fontWeight="bold">
         Gharama yake ni TSh 3,000/= kwa mwezi.
       </Typography>
       <Typography variant="h6" fontWeight="bold">
        Namna ya Kulipia
       </Typography>
      < Box>
      <List sx={{ listStyle: "decimal", pl: 4 }}>
      <ListItem sx={{ display: "list-item" }}>
    <ListItemText primary="Utapata siku 7 Bure punde tu utakapojisajili na baada ya hapo" />
  </ListItem>
  <ListItem sx={{ display: "list-item" }}>
    <ListItemText primary="Utatuma pesa yako kwenda TigoPesa namba 0716 536 113" />
  </ListItem>
  <ListItem sx={{ display: "list-item" }}>
    <ListItemText primary="Tuma ujumbe kwenda 0716 536 113 ukitaja namba ya simu iliyosajiliwa kwenye duka husika na kiasi ulichotuma" />
  </ListItem>
  <ListItem sx={{ display: "list-item" }}>
    <ListItemText primary="Akaunti yako itawezeshwa ndani ya muda mfupi" />
  </ListItem>
</List>
      </Box> 

      <Typography variant="h6" fontWeight="bold">
        Price Per 30 Days is Tsh 3,000/=
       </Typography>
       <Typography variant="h6" fontWeight="bold">
       How to pay
       </Typography>
      < Box>
      <List sx={{ listStyle: "decimal", pl: 4 }}>
      <ListItem sx={{ display: "list-item" }}>
    <ListItemText primary="You will have a 7 days free to try the software and then " />
  </ListItem>
  <ListItem sx={{ display: "list-item" }}>
    <ListItemText primary="You will be required to pay by Send your amount to TigoPesa Number 0716 536 113" />
  </ListItem>
  <ListItem sx={{ display: "list-item" }}>
    <ListItemText primary="Send the Payment message to 0716 536 113. Include the number registered to the system that associate with your Shop/Business" />
  </ListItem>
  <ListItem sx={{ display: "list-item" }}>
    <ListItemText primary="Your Account will be activated Shortily" />
  </ListItem>
</List>
      </Box> 
 </>
  );
}
