import { Card, CardContent, Typography, Icon } from "@mui/material";
import React from "react";

// Define the styles for the card
interface CardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
}

const MaterialUICard: React.FC<CardProps> = ({ title, description, icon }) => {
  const cardStyle: React.CSSProperties = {
    minWidth: 180,
    maxWidth: 275,
  };

  const titleStyle: React.CSSProperties = {
    fontSize: 14,
  };

  const iconStyle: React.CSSProperties = {
    marginRight: "8px",
  };

  return (
    <Card
      style={cardStyle}
      sx={{
        backgroundColor: "#7986cb",
        color: "#eee",
      }}
    >
      <CardContent>
        <Typography style={titleStyle} gutterBottom>
          {title}
        </Typography>
        <Typography variant="h6" component="h3">
          {icon && <Icon style={iconStyle}>{icon}</Icon>}
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MaterialUICard;
