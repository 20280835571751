import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import HotelIcon from '@mui/icons-material/Hotel';
import Typography from '@mui/material/Typography';
import YouTubeVideo from '../media/YouTubeVideo';
export default function HowToUse() {
  return (
    <>
     <Timeline position="alternate">

    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot color="primary" variant="outlined">
          <HotelIcon />
        </TimelineDot>
        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Typography variant="h6" component="span" fontWeight="bold">
          Jisajili (Register)
        </Typography>
        <Typography>Nenda kwenye fomu ya kujisajili ili kujisajili, Jaza Taarifa zako kiusahihi, 
          Namba ya simu utakayoiweka ndio itakayotumika Muda wa kulogin.</Typography>
          <Typography> - Got to registration form and register, 
            Remember your phone number will be used as a username during login.</Typography>
      </TimelineContent>
    </TimelineItem>



    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot color="primary" variant="outlined">
          <HotelIcon />
        </TimelineDot>
        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Typography variant="h6" component="span" fontWeight="bold">
          Ingia kwenye Mfumo (Login to system)
        </Typography>
        <Typography>Jaza fomu ya kuingia kwa kuweka namba yako ya simu na Nywila yako ulioiset wakati wa usajili</Typography>
        <Typography> - Fill the login form to Login, provide Phone number and password</Typography>
      </TimelineContent>
    </TimelineItem>


    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot color="primary" variant="outlined">
          <HotelIcon />
        </TimelineDot>
        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Typography variant="h6" component="span" fontWeight="bold">
         Sajili bidhaa yako (Register your products)
        </Typography>
        <Typography>Hakikisha kwanza umesajili brand zako zote kwenye mfumo, Samsung, tecno nk, 
          Kusajili brand, bonyeza menu ya brand kushoto, utaona brand zilizosajiliwa, bonyeza ongeza ili kuongeza brand. 
          Baada ya hapo utaenda sehemu ya Bidhaa ndipo utasajili bidhaa zote ambazo zinapatikana kwenye bishara yako, ambazo baadae utazichagua kwenye mauzo na manunuzi</Typography>
        <Typography> - Ensure all brand are recorded, then go to product menu the add product of which you will select them during buying and sale</Typography>
      </TimelineContent>
    </TimelineItem>


    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot color="primary" variant="outlined">
          <HotelIcon />
        </TimelineDot>
        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Typography variant="h6" component="span" fontWeight="bold">
         Ongeza Akiba (Add to Stock)
        </Typography>
        <Typography>Baada ya kusajili bidhaa zako, nenda kwenye sehemu ya akiba na utaweza kuongeza akiba kwenye mfumo kwa kujaza fomu</Typography>
        <Typography>After all Products Has been registered, click on stock menu to add Stock of which you will sale leter.</Typography>
      </TimelineContent>
    </TimelineItem>


    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot color="primary" variant="outlined">
          <HotelIcon />
        </TimelineDot>
        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Typography variant="h6" component="span" fontWeight="bold">
         Uza (Sale)
        </Typography>
        <Typography>Hakikisha Una akiba, nenda kwenye menyu ya kuuza na kisha jaza fomu ya kuweka kwenye kapu ukishamaliza kujaza 
          kapu lako, Utaangalia kwenye kapu upande wa kulia ama chini wa watumiaji wa simu, 
          Utaweka jina la mteja(Si lazima), baada ya hapo unabonyeza kitufe cha kuuza, hapo akiba yako itapungua</Typography>
        <Typography> - After add all your item to stock, then you can sale. Click on sale menu, then select product and add them to cart,
          after you add all roduct to cart then click sale button 
        </Typography>
      </TimelineContent>
    </TimelineItem>


    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot color="primary" variant="outlined">
          <HotelIcon />
        </TimelineDot>
        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Typography variant="h6" component="span" fontWeight="bold">
         Angalia mauzo (View Sales)
        </Typography>
        <Typography>Bonyeza menyu ya mauzo uangalie mauzo yako, unaweza kuchagua tarehe ya kwanza na ya mwisho ya mauzo yako</Typography>
        <Typography>To view daily sales, Click on sale menu and you will see the list of daily sale, you can choose date.</Typography>
      </TimelineContent>
    </TimelineItem>

    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot color="primary" variant="outlined">
          <HotelIcon />
        </TimelineDot>
        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Typography variant="h6" component="span" fontWeight="bold">
         Angalia Manunuzi (View Buying)
        </Typography>
        <Typography>Kuangalia manunuzi ama jinsi ulivyoongeza akiba yako kabla hujauza, bonyeza menyu ya Manunuzi uangalie vitu ulivyoongeza kwenye akiba yako vya kila siku, unaweza pia kuchagua tarehe ili kupata manunuzi ya tarehe husika</Typography>
        <Typography>Kusajili brand, bonyeza menu ya brand kushoto, utaona brand zilizosajiliwa, bonyeza ongeza ili kuongeza brand.</Typography>
      </TimelineContent>
    </TimelineItem>

    {/* <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot color="primary" variant="outlined">
          <HotelIcon />
        </TimelineDot>
        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Typography variant="h6" component="span" fontWeight="bold">
         Sajili mapato (Record other Income)
        </Typography>
        <Typography>Hakikisha kwanza umesajili brand zako zote kwenye mfumo, Samsung, tecno nk</Typography>
        <Typography>Kusajili brand, bonyeza menu ya brand kushoto, utaona brand zilizosajiliwa, bonyeza ongeza ili kuongeza brand.</Typography>
      </TimelineContent>
    </TimelineItem>


    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot color="primary" variant="outlined">
          <HotelIcon />
        </TimelineDot>
        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Typography variant="h6" component="span" fontWeight="bold">
         Sajili matumizi (Record other Income)
        </Typography>
        <Typography>Hakikisha kwanza umesajili brand zako zote kwenye mfumo, Samsung, tecno nk</Typography>
        <Typography>Kusajili brand, bonyeza menu ya brand kushoto, utaona brand zilizosajiliwa, bonyeza ongeza ili kuongeza brand.</Typography>
      </TimelineContent>
    </TimelineItem>

    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot color="primary" variant="outlined">
          <HotelIcon />
        </TimelineDot>
        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Typography variant="h6" component="span" fontWeight="bold">
         Sajili watumiaji wa mfumo (Register Staff)
        </Typography>
        <Typography>Hakikisha kwanza umesajili brand zako zote kwenye mfumo, Samsung, tecno nk</Typography>
        <Typography>Kusajili brand, bonyeza menu ya brand kushoto, utaona brand zilizosajiliwa, bonyeza ongeza ili kuongeza brand.</Typography>
      </TimelineContent>
    </TimelineItem> */}

  </Timeline>
  {/* <Typography variant="h6" fontWeight="bold">Unaweza Kuangalia Video hapa chini (You may watch the following Video)</Typography>
  <YouTubeVideo videoId={"rKwBxxVXWkM"} />   */}
  </>
  );
}
