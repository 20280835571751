export function getDateDifferenceInDays(endDate: Date): number {
  endDate = new Date(endDate);
  const startDate = new Date();
  // Calculate the time difference in milliseconds
  const timeDifference = endDate.getTime() - startDate.getTime();

  // Convert milliseconds to days
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
}
